import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filesize',
})
export class FilesizePipe implements PipeTransform {
  transform(filesizeInBytes: number): string {
    const decimals = 2;
    if (filesizeInBytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(filesizeInBytes) / Math.log(k));
    return (
      parseFloat((filesizeInBytes / Math.pow(k, i)).toFixed(dm)) +
      ' ' +
      sizes[i]
    );
  }
}
