<div class="GridDiv">
  <mat-label class="title"></mat-label>
  <div
    class="container"
    appDragAndDrop
    (fileDropped)="prepareFilesList($event)"
    *ngIf="!(layoutService.isHandset$ | async)"
  >
    <input
      type="file"
      #fileDropRef
      id="fileDropRef"
      multiple
      (change)="prepareFilesList($event.target.files)"
      [disabled]="ITEMID === 'new'"
    />
    <mat-icon class="icon-display">upload_file</mat-icon>
    <h3>{{ 'Generic.dragAndDropFiles' | translate }}</h3>
    <h3>{{ 'Generic.or' | translate }}</h3>
    <label for="fileDropRef">{{ 'Generic.browseForFiles' | translate }}</label>
  </div>
  <div *ngIf="layoutService.isHandset$ | async" class="mobileUploadArea">
    <input
      hidden
      type="file"
      #fileDropRef
      multiple
      (change)="prepareFilesList($event.target.files)"
    />
    <button
      mat-raised-button
      color="primary"
      aria-label="file browse button"
      (click)="fileDropRef.click()"
    >
      {{ 'Generic.browseForFiles' | translate }}
    </button>
  </div>
  <div class="files-list">
    <div *ngIf="uploadedFiles.length === 0" class="infobox">
      {{ 'Generic.noSavedFiles' | translate }}
    </div>
    <div class="single-file" *ngFor="let file of uploadedFiles; let i = index">
      <mat-icon
        aria-hidden="false"
        aria-label="File Icon"
        fontIcon="description"
        class="file-icon"
      ></mat-icon>
      <div class="info">
        <h4 class="name">
          {{ file.fileName }}
        </h4>
        <p class="size">
          {{ file.fileSize | filesize }}
        </p>
      </div>
      <button
        mat-icon-button
        color="primary"
        aria-label="download button"
        (click)="downloadFile(file.id, file.fileName)"
      >
        <mat-icon>download</mat-icon>
      </button>
      <button
        mat-icon-button
        color="warn"
        aria-label="delete button"
        (click)="deleteFile(file.id)"
      >
        <mat-icon>delete</mat-icon>
      </button>
    </div>
  </div>
</div>
